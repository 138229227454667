.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007BFF; /* Azul marinho */
  color: white;
  padding: 20px;
  position: relative;
}

.logo-link {
  text-decoration: none;
  color: white;
}

.logo {
  font-size: 1.8rem;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 15px;
}

.nav-item {
  text-decoration: none;
  color: white;
  font-weight: bold;
  display: block; /* Garante que os links ocupem o espaço todo em dispositivos móveis */
  padding: 10px 0; /* Melhor espaçamento entre os links no menu hambúrguer */
}

.btn-access {
  background-color: #005b96; /* Azul intermediário */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-access:hover {
  background-color: #003d73; /* Azul mais escuro ao passar o mouse */
}

.hamburger-menu {
  display: none;
  cursor: pointer;
}

.hamburger-menu.open .bar {
  background-color: #005b96; /* Azul intermediário */
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #00264d; /* Azul marinho */
    padding: 15px;
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    gap: 10px; /* Espaçamento entre os links */
  }

  .nav-links.open {
    display: flex;
  }

  .hamburger-menu {
    display: block;
  }
}
