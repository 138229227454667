.app {
    display: flex;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 1px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
  .footer {
    background-color: #1E3A8A; /* Cor do rodapé */
    color: #FFFFFF;
    padding: 20px;
    text-align: center; /* Centraliza o texto */
  }
  