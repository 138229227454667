.footer {
  background-color: #1E3A8A;
  color: #FFFFFF;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-wrap: wrap; /* Permite que os itens do footer se reorganizem em telas menores */
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 10px 0; /* Ajuste para manter o rodapé mais compacto */
  border-bottom: 1px solid #ffffff33;
}

.footer-section {
  flex: 1;
  margin: 10px; /* Ajuste para dar mais espaço e permitir melhor reorganização */
  min-width: 200px; /* Garante que os itens não fiquem muito pequenos */
}

.footer-section h4 {
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #F97316;
}

.footer-section p {
  margin: 5px 0;
  font-size: 0.9rem;
  line-height: 1.6;
}

.footer-bottom {
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.footer-bottom p {
  font-size: 0.8rem;
  color: #ffffff99;
}
