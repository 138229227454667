.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.supplier-list {
  flex: 1;
  padding: 20px;
  background-color: #f3f4f6;
}

.page-title {
  font-size: 1.8rem;
  color: #1E3A8A;
  margin-bottom: 20px;
  text-align: center;
}

.supplier-category {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  padding: 20px;
}

.category-title {
  font-size: 1.4rem;
  color: #F97316;
  margin-bottom: 15px;
}

.supplier-table {
  width: 100%;
  border-collapse: collapse;
}

.supplier-table th,
.supplier-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

.supplier-table th {
  background-color: #E3EAFD;
  color: #1E3A8A;
}

.supplier-table td {
  background-color: #ffffff;
}

.actions-column {
  text-align: center;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.view-details-button,
.delete-button {
  background: #F97316;
  border: none;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-details-button:hover {
  background: #e6630d;
}

.delete-button {
  background: #EF4444;
}

.delete-button:hover {
  background: #d73333;
}

.loading-message,
.error-message,
.no-data-message {
  text-align: center;
  margin-top: 20px;
  color: #1E3A8A;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin: 10px;
  overflow-y: auto;
  padding: 20px;
}

.add-supplier-button {
  background-color: #1976d2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-supplier-button:hover {
  background-color: #115293;
}
