/* src/ServiceDetails.css */
.service-details {
  padding: 20px;
  background-color: #f4f4f9;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #007BFF;
  margin-bottom: 20px;
}

section {
  margin-bottom: 20px;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

h3 {
  border-bottom: 2px solid #007BFF;
  padding-bottom: 5px;
  color: #333;
}

p {
  margin: 8px 0;
  font-size: 0.95em;
}

.service-details-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.service-details-table th, .service-details-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.service-details-table th {
  background-color: #f0f0f0;
}

.calculation-info {
  background-color: #e3f2fd;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
}

.status-bar {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 10px 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.status-step {
  flex: 1;
  text-align: center;
  padding: 5px;
  background-color: #ddd;
  border-radius: 5px;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.status-step.active {
  background-color: #007BFF;
  color: #fff;
}

/* src/ServiceDetails.css */
.status-bar {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 8px;
}

.status-step {
  flex: 1;
  text-align: center;
  padding: 10px;
  background-color: #ddd;
  border-radius: 8px;
  margin: 0 5px;
  transition: background-color 0.3s, color 0.3s;
}

.status-step.active {
  background-color: #4caf50; /* Cor para os passos completados */
  color: white;
}

.status-step:not(.active) {
  color: #666;
}
