.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #e2e8f0;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f3f4f6;
  padding: 5px 10px;
  border-radius: 20px;
}

.search-bar input {
  border: none;
  background: transparent;
  margin-left: 8px;
  outline: none;
  width: 100%;
}

.header-icons {
  display: flex;
  align-items: center;
}

.header-icons .icon {
  margin-left: 15px;
  font-size: 1.4rem;
  color: #1E3A8A;
}
